/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @material-ui/icons
import SwapHoriz from '@material-ui/icons/SwapHoriz';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';

import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import ReactSelect from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Danger from 'components/Typography/Danger.jsx';
import Typography from '@material-ui/core/Typography';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// Fireabse
import InfoIcon from '@material-ui/icons/Info';
import {
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from '@material-ui/core';
import AddTruckersStyle from 'assets/jss/material-dashboard-pro-react/views/AddTruckersStyle.jsx';
import AddTruck from './Sections/AddTruck';
import firebase from '../../config/config';
import apiCaller from '../../utils/apiCallerOutstanding';
import './autoSelect.css';
import FileUploader from './Sections/AddTruckFiles/FileUploader';
import FeatureFlagToastAlert from '../Components/FeatureFlagToastAlert';
import OtherExpenses from './Sections/TripCalculation/OtherExpenses';
import TripDeductionFields from './Sections/TripCalculation/TripDeductionFields';
import AddManufacturer from './Sections/AddManufacturer';
import getManufacturerList from '../../utils/getManufacturerList';
import { getViewURL } from '../../utils/fileUploadHandler';
import EwayBillNumber from './Sections/NewFields/EwayBillNumber';

const db = firebase.firestore();
const addTransaction = firebase
  .functions()
  .httpsCallable('transactions-addTransaction');
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

class AddTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSubLocation: '',
      unloadingSubLocation: '',
      address: '',
      addressUnloading: '',
      selectedOption: null,
      transporterName: '',
      transporterNameState: '',
      truckerNameState: '',
      transporterId: '',
      truckerName: '',
      truckerId: '',
      fromCity: '',
      fromCityState: '',
      toCity: '',
      toCityState: '',
      vehicleNumber: '',
      vehicleNumberState: '',
      amount: '0',
      amountState: '',
      commission: '0',
      commissionState: '',
      loadingCharges: '0',
      loadingChargesState: '',
      bthReceivable: '0',
      bthReceivableState: '',
      bthPayable: '0',
      bthPayableState: '',
      athReceivable: '0',
      athReceivableState: '',
      athPayable: '0',
      athPayableState: '',
      selectedTruckerTrucks: [],
      truckType: '',
      truckTypeState: '',
      truckNumber: '',
      truckNumberState: '',
      companyName: '',
      isLoading: false,
      alert: null,
      companyId: '',
      trucks: [],
      selectedTruck: null,
      truckTypes: [
        '12T- Open',
        '20FT Container',
        '22FT Closed Container',
        '22FT Jcb Carrier',
        '24FT Closed Container',
        '24FT Jcb Carrier',
        '32FT MultiAxle Container',
        '32FT Single Axle Container',
        '34FT Multi Axle Container',
        '34FT Single Axle Container',
        'FTL',
        'High Bed Trailer',
        'High Bed Trailer Double Axle',
        'High Bed Trailer Multi Axle',
        'LCV Closed Container - 14FT',
        'LCV-14FT',
        'LCV-19FT',
        'LCV-7FT',
        'LCV-17FT',
        'Low Bed Trailer',
        'Low Bed Trailer Double Axle',
        'Low Bed Trailer Multi Axle',
        'LPT-19 FT',
        'LPT-19FT Closed Container',
      ],
      transporters: [],
      truckers: [],
      truckersOption: [],
      selectedTruckerId: '',
      fromCities: [],
      toCities: [],
      totalPayablePendingAmount: 0,
      totalReceivablePendingAmount: 0,
      totalCommission: 0,
      loadingDate: moment(),
      athReceivableDate: moment(),
      branches: [],
      branch: '',
      athPayableAutoCalculated: '0',
      bthPayableAutoCalculated: '0',
      selectedTrucker: null,
      selectedTransporter: null,
      guidancePrice: undefined,
      fromCitiesObject: [],
      selectedFromCity: null,
      selectedToCity: null,
      ledgerId: '',
      truckerPhoneNumber: '',
      selectedBranch: null,
      transporterCustomers: [],
      truckerCustomers: [],
      fetching: false,
      transporterPOC: '',
      truckerPOC: '',
      truckerATHPercentage: '0',
      truckerBTHPercentage: '0',
      trafficUsers: [],
      orderRecievedBy: [],
      orderRecievedByState: '',
      truckPlacedBy: [],
      truckPlacedByState: '',
      isFetchingTraffic: false,
      tonnage: '',
      tonnageState: '',
      expectedDate: '',
      driverNumber: '',
      driverNumberState: '',
      expectedDateState: '',
      showRCUploder: false,
      rcUploaded: false,
      selectedTruckHasRCUploadedBool: '',
      selectedTruckerTrucksOptions: [],
      selectedTruckerTruck: null,
      featureFlagFiles: '',
      hamaliChargesTrucker: 0,
      hamaliChargesTruckerState: '',
      hamaliChargesPaidByTrucker: false,
      hamaliChargesPaidByTransporter: false,
      hamaliChargesTransporter: 0,
      hamaliChargesTransporterState: '',
      haltingChargesTrucker: 0,
      haltingChargesTruckerState: '',
      haltingChargesTransporter: 0,
      haltingChargesTransporterState: '',
      haltingChargesPaidByTransporter: false,
      haltingChargesPaidByTrucker: false,
      loadingChargesTrucker: 0,
      loadingChargesTruckerState: '',
      unloadingChargesTrucker: 0,
      unloadingChargesTruckerState: '',
      loadingChargesTransporter: 0,
      loadingChargesTransporterState: '',
      unloadingChargesTransporter: 0,
      unloadingChargesTransporterState: '',
      loadingChargesPaidByTrucker: false,
      unloadingChargesPaidByTrucker: false,
      loadingChargesPaidByTransporter: false,
      unloadingChargesPaidByTransporter: false,
      challanTransporterAmount: 0,
      challanTransporterAmountState: '',
      challanTruckerAmount: 0,
      challanTruckerAmountState: '',
      penaltyTruckerAmount: 0,
      penaltyTruckerAmountState: '',
      fuelChargesTransporterAmount: 0,
      tdsTransporterAmount: 0,
      docChargesTransporterAmount: 0,
      penaltyTransporterAmount: 0,
      maamolChargesTransporterAmount: 0,
      maamolChargesTransporterAmountState: '',
      tdsTruckerAmount: 0,
      tdsTruckerAmountState: '',
      tdsTruckerShowinloadingslip: false,
      fuelChargesTruckerShowinloadingslip: false,
      fuelChargesTruckerAmount: 0,
      fuelChargesTruckerAmountState: '',
      docChargesTruckerShowinloadingslip: false,
      docChargesTruckerAmount: 0,
      docChargesTruckerAmountState: '',
      maamolChargesTruckerShowinloadingslip: false,
      maamolChargesTruckerAmount: 0,
      maamolChargesTruckerAmountState: '',
      shortageChargesTruckerShowinloadingslip: false,
      shortageChargesTruckerAmount: 0,
      shortageChargesTruckerAmountState: '',
      lateDeliveryTruckerShowinloadingslip: false,
      lateDeliveryTruckerAmount: 0,
      lateDeliveryTruckerAmountState: '',
      latePodTruckerShowinloadingslip: false,
      latePodTruckerAmount: 0,
      latePodTruckerAmountState: '',
      earlySettlementTruckerShowinloadingslip: false,
      earlySettlementTruckerAmount: 0,
      earlySettlementTruckerAmountState: '',
      transactionType: 'TAT',
      manufacturerList: [],
      loadingChargesTruckerError: '',
      unloadingChargesTruckerError: '',
      hamaliChargesTruckerError: '',
      haltingChargesTruckerError: '',
      loadingChargesTransporterError: '',
      unloadingChargesTransporterError: '',
      hamaliChargesTransporterError: '',
      haltingChargesTransporterError: '',
      challanTransporterAmountError: '',
      penaltyTransporterAmountError: '',
      tdsTransporterAmountError: '',
      docChargesTransporterAmountError: '',
      fuelChargesTransporterAmountError: '',
      maamolChargesTransporterAmountError: '',
      challanTruckerAmountError: '',
      penaltyTruckerAmountError: '',
      showTds: true,
      hardCodeTransactionNumber: 95670,
      latestTransactionNumber: '',
      tdsTruckerAmountError: '',
      fuelChargesTruckerAmountError: '',
      docChargesTruckerAmountError: '',
      maamolChargesTruckerAmountError: '',
      shortageChargesTruckerAmountError: '',
      lateDeliveryTruckerAmountError: '',
      latePodTruckerAmountError: '',
      earlySettlementTruckerAmountError: '',
      supplyUsers: [],
      supplyID: '',
      supplyIDState: '',
      demandID: '',
      demandIDState: '',
      isDemandValid: '',
      isSupplyValid: '',
      isValidatingID: '',
      tdsTruckerSubmitted: true,
      validPan: true,
      ewayBillNumber: '',
      isPodSubmittedAtUnloadingPoint: false,
    };
    this.change = this.change.bind(this);
    this.submitTransaction = this.submitTransaction.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.getTransporters = this.getTransporters.bind(this);
    this.getTruckers = this.getTruckers.bind(this);
    this.getCities = this.getCities.bind(this);
    this.handleTrucker = this.handleTrucker.bind(this);
    this.handleFromCity = this.handleFromCity.bind(this);
    this.handleToCity = this.handleToCity.bind(this);
    this.handleTransporter = this.handleTransporter.bind(this);
    this.handleTrucks = this.handleTrucks.bind(this);
    this.loadingDateChange = this.loadingDateChange.bind(this);
    this.athReceivableDateChange = this.athReceivableDateChange.bind(this);
    this.expectedDateChange = this.expectedDateChange.bind(this);
    this.otherExpenses = this.otherExpenses.bind(this);
    this.deductions = this.deductions.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.confirmAlert = this.confirmAlert.bind(this);
    this.handlePaidByTruckerSwitch = this.handlePaidByTruckerSwitch.bind(this);
    this.handleChangeTransactionType = this.handleChangeTransactionType.bind(
      this
    );
    this.handleChangeUnloadingPoint = this.handleChangeUnloadingPoint.bind(
      this
    );
  }

  componentDidMount() {
    const { userDetails } = this.props;
    getManufacturerList().then(listData =>
      this.setState({ manufacturerList: listData })
    );

    const { branches } = userDetails;
    let newBranches = [];
    branches.forEach(branch => {
      newBranches.push({
        label: branch,
        value: branch,
      });
    });
    // TODO: set branches properly for users with only one branch permitted
    if (newBranches.length > 0) {
      this.setState({ branches: newBranches });
    } else {
      this.setState({ branch: newBranches[0] });
    }
    this.getCities();
    this.getTruckers();
    this.getTransporters();
    this.getTraffic();
    this.getSupply();
    this.getFeatureFlagStatus();
  }

  getFeatureFlagStatus() {
    db.collection('FeatureFlags')
      .doc('FileUploadsFeature')
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ featureFlagFiles: data?.transactionFiles });
        }
      });
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleChangeUnloading = addressUnloading => {
    this.setState({ addressUnloading });
  };

  handleSelectUnloading = addressUnloading => {
    this.setState({ addressUnloading });
    geocodeByAddress(addressUnloading)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ unloadingSubLocation: latLng }))
      .catch(error => console.error('Error unloading', error));
  };

  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ loadingSubLocation: latLng }))
      .catch(error => console.error('Error', error));
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  confirmAlert() {
    const { activeRole } = this.props;
    // Set the CurrentTransaction in Redux Store
    // eslint-disable-next-line react/destructuring-assignment
    this.setState({
      alert: null,
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push(`/${activeRole}/transactions/view`);
  }

  /**
   * will return All the Truckers Available for User
   * @function
   * @param {String} companyId
   */
  getTruckers() {
    const TruckerRef = db.collection('Truckers');
    TruckerRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const truckers = [];
          const truckersOptions = [];
          resultData.forEach(trucker => {
            if (
              trucker.data().updatedByRole !== 'premiumTransporter' &&
              trucker.data().kycLevel !== '0'
            ) {
              const data = trucker.data();
              const number = data.phoneNumber;
              data.label = trucker.data().name;
              data.value = trucker.data().truckerId;
              data.truckerData = trucker.data();
              // if (!data?.blacklist) {
              truckers.push(data);
              truckersOptions.push({
                label: `${data.name} - ${String(number).slice(-4)}`,
                value: data,
                blacklisted: data?.blacklist,
              });
              // }
            }
          });
          this.setState({
            truckers,
            truckersOptions,
          });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  /**
   * will return All the Transporters Available for User
   * @function
   * @param {String} companyId
   */
  getTransporters() {
    const TransporterRef = db.collection(`Transporters`);
    TransporterRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const transporters = [];
          resultData.forEach(transporter => {
            //  Checks if transporter and user have any common branches
            const data = transporter.data();
            data.label = transporter.data().name;
            data.value = transporter.data().transporterId;
            data.blacklisted = transporter?.data()?.blacklist;
            // if (!data?.blacklist) {
            transporters.push(data);
            // }
          });
          this.setState({ transporters });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  // eslint-disable-next-line consistent-return
  async getTraffic() {
    this.setState({ isFetchingTraffic: true });
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const endPoint = 'pipe/get-lists';

    const body = {
      type: 'traffic_persons',
    };
    try {
      const response = await apiCaller(endPoint, body, `Bearer ${Aa}`, 'pipe');
      const trafficUsers = response?.data?.Data;
      this.setState({ trafficUsers, isFetchingTraffic: false });
    } catch (error) {
      this.setState({ isFetchingTraffic: false });
    }
  }

  async getSupply() {
    this.setState({ isFetchingTraffic: true });
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const endPoint = 'pipe/get-lists';

    const body = {
      type: 'supply_persons',
    };
    try {
      const response = await apiCaller(endPoint, body, `Bearer ${Aa}`, 'pipe');
      const supplyUsers = response?.data?.Data;
      this.setState({ supplyUsers, isFetchingTraffic: false });
    } catch (error) {
      this.setState({ isFetchingTraffic: false });
    }
  }

  /**
   * will return All the Cities Available for User
   * @function
   * @param {String} companyId
   */
  async getCities() {
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    try {
      const endPoint = 'pipe/get-lists';
      const body = {
        type: 'cities',
      };
      const response = await apiCaller(endPoint, body, `Bearer ${Aa}`, 'pipe');

      const citiesList = response?.data?.Data;
      const fromCitiesObject = [];
      // eslint-disable-next-line guard-for-in

      for (let i = 0; i < citiesList.length; i++) {
        fromCitiesObject.push({ value: citiesList[i], label: citiesList[i] });
      }

      this.setState({
        fromCitiesObject,
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    // console.log('verifyNumber', value);
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyNumberAndGreater(value, minValue) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value >= minValue) {
      return true;
    }
    return false;
  }

  verifyTonnage(value) {
    const numberRex = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyDriverNumber(value) {
    const numberRex = /^[6-9][0-9]{9}$/;
    if (numberRex.test(value) && value.length === 10) {
      return true;
    }
    return false;
  }

  verifyMaxNumber(value, maxValue) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value <= maxValue) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  async isAlphaNumeric(value, type) {
    const alphanumericRegex = /^[0-9a-zA-Z]+$/; // Regular expression to match alphanumeric characters
    const endPoint = 'pipe/trips';
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    this.setState({ isValidatingID: type });
    const body = {
      supply_id: type === 'supplyID' ? value : '',
      demand_id: type === 'demandID' ? value : '',
      type: 'validate_supply_traffic',
    };
    if (alphanumericRegex.test(value)) {
      try {
        const response = await apiCaller(
          endPoint,
          body,
          `Bearer ${Aa}`,
          'pipe',
          'post'
        );
        const result = response?.data?.status;
        if (type === 'supplyID') {
          this.setState({ isSupplyValid: result ? 'isValid' : 'isNotValid' });
        } else {
          this.setState({ isDemandValid: result ? 'isValid' : 'isNotValid' });
        }
        this.setState({ isValidatingID: '' });
      } catch (e) {
        this.setState({
          isDemandValid: 'isNotValid',
          isSupplyValid: 'isNotValid',
          isValidatingID: '',
        });
      }
      return true;
      // return alphanumericRegex.test(value);
    }
    return false;
  }

  verifyPhoneNumber(value) {
    const validatephoneNumberNumberRegex = /^[6-9][0-9]{9}$/;
    if (validatephoneNumberNumberRegex.test(value) && value.length === 10) {
      return true;
    }
    return false;
  }

  // Will Verify cities
  verifyCities(value) {
    if (value.length < 2) {
      return false;
    }
    return true;
  }

  calculateFields() {
    const {
      amount,
      athPayable,
      bthPayable,
      athReceivable,
      loadingChargesPaidByTrucker,
      unloadingChargesPaidByTrucker,
      hamaliChargesPaidByTrucker,
      haltingChargesPaidByTrucker,
      challanTruckerAmount,
      penaltyTruckerAmount,
      tdsTruckerAmount,
      fuelChargesTruckerAmount,
      docChargesTruckerAmount,
      maamolChargesTruckerAmount,
      shortageChargesTruckerAmount,
      lateDeliveryTruckerAmount,
      latePodTruckerAmount,
      earlySettlementTruckerAmount,
      challanTransporterAmount,
      penaltyTransporterAmount,
      tdsTransporterAmount,
      docChargesTransporterAmount,
      fuelChargesTransporterAmount,
      maamolChargesTransporterAmount,
      loadingChargesPaidByTransporter,
      unloadingChargesPaidByTransporter,
      loadingChargesTransporter,
      unloadingChargesTransporter,
      hamaliChargesTransporter,
      hamaliChargesPaidByTransporter,
      haltingChargesPaidByTransporter,
      haltingChargesTransporter,
      loadingChargesTrucker,
      unloadingChargesTrucker,
      hamaliChargesTrucker,
      haltingChargesTrucker,
    } = this.state;

    const bthReceivable = amount - athReceivable;

    this.setState({ bthReceivable });

    if (this.verifyNumber(bthReceivable))
      this.setState({
        bthReceivableState: 'success',
      });
    else
      this.setState({
        bthReceivableState: 'This field is required',
      });

    if (this.verifyNumber(athPayable))
      this.setState({
        athPayableState: 'success',
      });
    else
      this.setState({
        athPayableState: 'This field is required',
      });

    if (this.verifyNumber(bthPayable))
      this.setState({
        bthPayableState: 'success',
      });
    else
      this.setState({
        bthPayableState: 'This field is required',
      });

    //  Total Payable Calculation
    let payableDeductions = 0;
    if (Number(challanTruckerAmount) > 0)
      payableDeductions += Number(challanTruckerAmount);
    if (Number(penaltyTruckerAmount) > 0)
      payableDeductions += Number(penaltyTruckerAmount);

    if (Number(tdsTruckerAmount) > 0)
      payableDeductions += Number(tdsTruckerAmount);
    if (Number(docChargesTruckerAmount) > 0)
      payableDeductions += Number(docChargesTruckerAmount);
    if (Number(maamolChargesTruckerAmount) > 0)
      payableDeductions += Number(maamolChargesTruckerAmount);
    if (Number(shortageChargesTruckerAmount) > 0)
      payableDeductions += Number(shortageChargesTruckerAmount);
    if (Number(lateDeliveryTruckerAmount) > 0)
      payableDeductions += Number(lateDeliveryTruckerAmount);
    if (Number(latePodTruckerAmount) > 0)
      payableDeductions += Number(latePodTruckerAmount);
    if (Number(earlySettlementTruckerAmount) > 0)
      payableDeductions += Number(earlySettlementTruckerAmount);
    if (Number(fuelChargesTruckerAmount) > 0)
      payableDeductions += Number(fuelChargesTruckerAmount);

    let receivableDeductions = 0;
    if (Number(challanTransporterAmount) > 0)
      receivableDeductions += Number(challanTransporterAmount);
    if (Number(fuelChargesTransporterAmount) > 0)
      receivableDeductions += Number(fuelChargesTransporterAmount);
    if (Number(tdsTransporterAmount) > 0)
      receivableDeductions += Number(tdsTransporterAmount);
    if (Number(docChargesTransporterAmount) > 0)
      receivableDeductions += Number(docChargesTransporterAmount);
    if (Number(penaltyTransporterAmount) > 0)
      receivableDeductions += Number(penaltyTransporterAmount);
    if (Number(maamolChargesTransporterAmount) > 0)
      receivableDeductions += Number(maamolChargesTransporterAmount);

    // let otherExpensesPayable = 0;
    let otherExpensesReceivable = 0;
    if (
      loadingChargesPaidByTransporter &&
      Number(loadingChargesTransporter) > 0
    ) {
      otherExpensesReceivable += Number(loadingChargesTransporter);
    }
    if (
      unloadingChargesPaidByTransporter &&
      Number(unloadingChargesTransporter) > 0
    ) {
      otherExpensesReceivable += Number(unloadingChargesTransporter);
    }

    if (
      hamaliChargesPaidByTransporter &&
      Number(hamaliChargesPaidByTransporter) > 0
    ) {
      otherExpensesReceivable += Number(hamaliChargesTransporter);
    }

    if (
      haltingChargesPaidByTransporter &&
      Number(haltingChargesTransporter) > 0
    ) {
      otherExpensesReceivable += Number(haltingChargesTransporter);
    }

    let otherExpensesPaidByTrucker = 0;
    if (loadingChargesPaidByTrucker && Number(loadingChargesTrucker) > 0)
      otherExpensesPaidByTrucker += Number(loadingChargesTrucker);
    if (unloadingChargesPaidByTrucker && Number(unloadingChargesTrucker) > 0)
      otherExpensesPaidByTrucker += Number(unloadingChargesTrucker);
    if (hamaliChargesPaidByTrucker && Number(hamaliChargesTrucker) > 0)
      otherExpensesPaidByTrucker += Number(hamaliChargesTrucker);
    if (haltingChargesPaidByTrucker && Number(haltingChargesTrucker) > 0)
      otherExpensesPaidByTrucker += Number(haltingChargesTrucker);
    const totalPayableAmount =
      Number(athPayable) +
      Number(bthPayable) -
      Number(payableDeductions) +
      Number(otherExpensesPaidByTrucker);
    // + otherExpensesPayable;
    const totalReceivableAmount =
      Number(athReceivable) +
      Number(bthReceivable) -
      receivableDeductions +
      otherExpensesReceivable;

    const athPayableAutoCalculated = athPayable - payableDeductions; // + otherExpensesPayable;
    const bthPayableAutoCalculated =
      Number(bthPayable) + Number(otherExpensesPaidByTrucker);
    const calculatedCommision = totalReceivableAmount - totalPayableAmount;
    this.setState({
      totalPayableAmount,
      totalReceivableAmount,
      otherExpensesReceivable,
      payableDeductions,
      receivableDeductions,
      netCommission: calculatedCommision,
      athPayableAutoCalculated,
      bthPayableAutoCalculated,
    });
  }

  handleTruckerATHPercentage(val, commission, amount) {
    // Convert strings to numbers
    const numericAmount = parseInt(amount);
    const numericCommission = parseInt(commission);
    if (numericAmount !== 0 && numericCommission !== 0) {
      const payable = numericAmount - numericCommission;

      // Check if payable is positive before calculating the percentage
      if (payable > 0) {
        const percentage = Math.round((val / payable) * 100);
        this.setState({ truckerATHPercentage: percentage });
      } else {
        this.setState({ truckerATHPercentage: 0 }); // Set percentage to 0 if payable is not positive
      }
    }
  }

  handleTruckerBTHPercentage(val, commission, amount) {
    // Convert strings to numbers
    const numericAmount = parseInt(amount);
    const numericCommission = parseInt(commission);
    if (numericAmount !== 0 && numericCommission !== 0) {
      const payable = numericAmount - numericCommission;

      // Check if payable is positive before calculating the percentage
      if (payable > 0) {
        const percentage = Math.round((val / payable) * 100);
        this.setState({ truckerBTHPercentage: percentage });
      } else {
        this.setState({ truckerBTHPercentage: 0 }); // Set percentage to 0 if payable is not positive
      }
    }
  }

  handleTruckerATH(amount, val) {
    const result = amount - val;
    let truckerATH = Math.round(result * 0.85);
    let truckerBTH = Math.round(result - truckerATH);
    this.handleTruckerATHPercentage(truckerATH, val, amount);
    this.handleTruckerBTHPercentage(truckerBTH, val, amount);
    this.setState({ athPayable: truckerATH, bthPayable: truckerBTH });
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          // if (stateName === 'bthPayable') {
          //   this.handleTruckerBTHPercentage(event.target.value);
          // }
          if (stateName === 'commission') {
            this.handleTruckerATH(this.state.amount, event.target.value);
          }
          this.setState(
            {
              [`${stateName}State`]: 'success',
              [stateName]: event.target.value,
            },
            this.calculateFields
          );
        } else {
          this.setState({
            [`${stateName}State`]: 'This field is required',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'maxNumber':
        if (this.verifyMaxNumber(event.target.value, stateNameEqualTo)) {
          // if (stateName === 'athPayable') {
          //   this.handleTruckerATHPercentage(this.state.athPayable);
          // }

          this.setState(
            {
              [`${stateName}State`]: 'success',
              [stateName]: event.target.value,
            },
            this.calculateFields
          );
        } else {
          this.setState({
            [`${stateName}State`]: 'This field is required',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          console.log('event.target.value', event.target.value);
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'This field is required',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'greater':
        if (this.verifyNumberAndGreater(event.target.value, stateNameEqualTo)) {
          if (stateName === 'amount') {
            this.handleTruckerATH(event.target.value, this.state.commission);
          }

          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'This field is required',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'tonnage':
        if (this.verifyTonnage(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'This field is required',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'driverNumber':
        if (this.verifyDriverNumber(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'This field is required',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'isAlphaNumeric':
        if (this.isAlphaNumeric(event.target.value, stateName)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'This field is required',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const { classes } = this.props;
    const {
      transporterName,
      truckerName,
      branch,
      fromCity,
      toCity,
      amount,
      commission,
      bthPayable,
      bthReceivable,
      athPayable,
      athReceivable,
      truckNumber,
      expectedDate,
      driverNumber,
      hamaliChargesTrucker,
      haltingChargesTrucker,
      loadingChargesTrucker,
      unloadingChargesTrucker,
      hamaliChargesTransporter,
      haltingChargesTransporter,
      loadingChargesTransporter,
      unloadingChargesTransporter,
      challanTransporterAmount,
      penaltyTransporterAmount,
      tdsTransporterAmount,
      docChargesTransporterAmount,
      fuelChargesTransporterAmount,
      maamolChargesTransporterAmount,
      challanTruckerAmount,
      penaltyTruckerAmount,
      tdsTruckerAmount,
      fuelChargesTruckerAmount,
      docChargesTruckerAmount,
      maamolChargesTruckerAmount,
      shortageChargesTruckerAmount,
      lateDeliveryTruckerAmount,
      latePodTruckerAmount,
      earlySettlementTruckerAmount,
      supplyID,
      demandID,
      isDemandValid,
      isSupplyValid,
    } = this.state;
    let error = false;
    let errorReason = '';
    return new Promise((resolve, reject) => {
      // Verify  Transporter Name
      if (this.verifyLength(transporterName, 1)) {
        this.setState({
          transporterNameState: 'success',
        });
      } else {
        this.setState({ transporterNameState: 'This field is required' });
        error = true;
        errorReason = ' transporterName validation fail';
      }

      // Verify  Trucker Name
      if (this.verifyLength(truckerName, 1)) {
        this.setState({
          truckerNameState: 'success',
        });
      } else {
        this.setState({ truckerNameState: 'This field is required' });
        error = true;
        errorReason = ' trucker name validation fail';
      }

      if (this.verifyLength(branch, 1)) {
        this.setState({
          branchState: 'success',
        });
      } else {
        this.setState({ branchState: 'This field is required' });
        error = true;
        errorReason = ' branch validation fail';
      }

      // Verify  From City
      if (this.verifyLength(fromCity, 1)) {
        this.setState({
          fromCityState: 'success',
        });
      } else {
        this.setState({ fromCityState: 'This field is required' });
        error = true;
        errorReason = ' from city validation fail';
      }
      // Verify  To City
      if (this.verifyLength(toCity, 1)) {
        this.setState({
          toCityState: 'success',
        });
      } else {
        this.setState({ toCityState: 'This field is required' });
        error = true;
        errorReason = ' to city validation fail';
      }
      // Verify Supply ID
      if (this.verifyLength(supplyID, 1) && isSupplyValid === 'isValid') {
        this.setState({
          supplyIDState: 'success',
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              Please check Supply ID
            </SweetAlert>
          ),
          supplyIDState: 'This field is required',
        });
        error = true;
        errorReason = 'Supply ID mandatory';
      }
      // Verify Demand ID
      if (this.verifyLength(demandID, 1) && isDemandValid === 'isValid') {
        this.setState({
          demandIDState: 'success',
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              Please check Demand ID
            </SweetAlert>
          ),
          demandIDState: 'This field is required',
        });
        error = true;
        errorReason = 'Demand ID mandatory';
      }

      // Verify  Amount
      if (this.verifyNumberAndGreater(amount, 1)) {
        this.setState({
          amountState: 'success',
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              The Freight Value must be a minimum of Rs 1.
            </SweetAlert>
          ),
          amountState: 'This field is required',
        });
        error = true;
        errorReason = ' freight validation fail';
      }
      // Verify  Commission
      if (this.verifyNumber(commission)) {
        this.setState({
          commissionState: 'success',
        });
      } else {
        this.setState({ commissionState: 'This field is required' });
        error = true;
        errorReason = ' commsion validation fail';
      }

      // Verify  BTH Payable
      if (this.verifyNumber(bthPayable)) {
        this.setState({
          bthPayableState: 'success',
        });
      } else {
        this.setState({ bthPayableState: 'This field is required' });
        error = true;
        errorReason = ' bth payable validation fail';
      }
      // Verify  Commission
      if (this.verifyNumber(bthReceivable)) {
        this.setState({
          bthReceivableState: 'success',
        });
      } else {
        this.setState({ bthReceivableState: 'This field is required' });
        error = true;
        errorReason = ' bth recievable validation fail';
      }
      // Verify  Commission
      if (this.verifyMaxNumber(athPayable, 199999)) {
        this.setState({
          athPayableState: 'success',
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              Trucker ATH Limit Exceeded
            </SweetAlert>
          ),
          athPayableState: 'Trucker ATH Limit Exceeded',
        });
        error = true;
        errorReason = ' ath payable validation fail';
      }

      // Verify  Commission
      if (this.verifyNumber(athReceivable)) {
        this.setState({
          athReceivableState: 'success',
        });
      } else {
        this.setState({ athReceivableState: 'This field is required' });
        error = true;
        errorReason = ' ath recievable validation fail';
      }
      // Verify  Truck Number
      if (this.verifyLength(truckNumber, 1)) {
        this.setState({ truckNumberState: 'success' });
      } else {
        this.setState({ truckNumberState: 'This field is required' });
        error = true;
        errorReason = ' trcuk number validation fail';
      }
      // Verify whether the amount is less the commission
      if (parseInt(amount, 10) < parseInt(commission, 10)) {
        this.setState({
          commissionState: 'This field is required',
        });
        error = true;
        errorReason = ' commision validation fail';
      }

      // Verify expected date
      if (
        this.verifyLength(
          expectedDate ? expectedDate?.format('MMM Do YY') : '',
          1
        )
      ) {
        this.setState({
          expectedDateState: 'success',
        });
      } else {
        this.setState({ expectedDateState: 'This field is required' });
        error = true;
        errorReason = ' expecetd date validation fail';
      }
      // Verify driverNumber
      if (this.verifyPhoneNumber(driverNumber)) {
        this.setState({
          driverNumberState: 'success',
        });
      } else {
        this.setState({ driverNumberState: 'This field is required' });
        error = true;
        errorReason = ' driver number validation fail';
      }

      // Verify  hamaliChargesTrucker
      if (this.verifyNumber(hamaliChargesTrucker)) {
        this.setState({
          hamaliChargesTruckerState: 'success',
        });
      } else {
        this.setState({ hamaliChargesTruckerState: 'This field is required' });
        error = true;
        errorReason = ' hamaliChargesTrucker validation fail';
      }
      // Verify  haltingChargesTrucker
      if (this.verifyNumber(haltingChargesTrucker)) {
        this.setState({
          haltingChargesTruckerState: 'success',
        });
      } else {
        this.setState({ haltingChargesTruckerState: 'This field is required' });
        error = true;
        errorReason = ' haltingChargesTrucker validation fail';
      }
      // Verify  loadingChargesTrucker
      if (this.verifyNumber(loadingChargesTrucker)) {
        this.setState({
          loadingChargesTruckerState: 'success',
        });
      } else {
        this.setState({ loadingChargesTruckerState: 'This field is required' });
        error = true;
        errorReason = ' loadingChargesTrucker validation fail';
      }

      // Verify  unloadingChargesTrucker
      if (this.verifyNumber(unloadingChargesTrucker)) {
        this.setState({
          unloadingChargesTruckerState: 'success',
        });
      } else {
        this.setState({
          unloadingChargesTruckerState: 'This field is required',
        });
        error = true;
        errorReason = ' unloadingChargesTrucker validation fail';
      }

      // Verify  hamaliChargesTransporter
      if (this.verifyNumber(hamaliChargesTransporter)) {
        this.setState({
          hamaliChargesTransporterState: 'success',
        });
      } else {
        this.setState({
          hamaliChargesTransporterState: 'This field is required',
        });
        error = true;
        errorReason = ' hamaliChargesTransporter validation fail';
      }

      // Verify  haltingChargesTransporter
      if (this.verifyNumber(haltingChargesTransporter)) {
        this.setState({
          haltingChargesTransporterState: 'success',
        });
      } else {
        this.setState({
          haltingChargesTransporterState: 'This field is required',
        });
        error = true;
        errorReason = ' haltingChargesTransporter validation fail';
      }

      // Verify  loadingChargesTransporter
      if (this.verifyNumber(loadingChargesTransporter)) {
        this.setState({
          loadingChargesTransporterState: 'success',
        });
      } else {
        this.setState({
          loadingChargesTransporterState: 'This field is required',
        });
        error = true;
        errorReason = ' loadingChargesTransporter validation fail';
      }

      // Verify  unloadingChargesTransporter
      if (this.verifyNumber(unloadingChargesTransporter)) {
        this.setState({
          unloadingChargesTransporterState: 'success',
        });
      } else {
        this.setState({
          unloadingChargesTransporterState: 'This field is required',
        });
        error = true;
        errorReason = ' unloadingChargesTransporter validation fail';
      }

      // Verify  challanTransporterAmount
      if (this.verifyNumber(challanTransporterAmount)) {
        this.setState({
          challanTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          challanTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' challanTransporterAmount validation fail';
      }

      // Verify  penaltyTransporterAmount
      if (this.verifyNumber(penaltyTransporterAmount)) {
        this.setState({
          penaltyTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          penaltyTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' penaltyTransporterAmount validation fail';
      }

      // Verify  tdsTransporterAmount
      if (this.verifyNumber(tdsTransporterAmount)) {
        this.setState({
          tdsTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          tdsTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' tdsTransporterAmount validation fail';
      }

      // Verify  docChargesTransporterAmount
      if (this.verifyNumber(docChargesTransporterAmount)) {
        this.setState({
          docChargesTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          docChargesTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' docChargesTransporterAmount validation fail';
      }

      // Verify  fuelChargesTransporterAmount
      if (this.verifyNumber(fuelChargesTransporterAmount)) {
        this.setState({
          fuelChargesTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          fuelChargesTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' fuelChargesTransporterAmount validation fail';
      }

      // Verify  maamolChargesTransporterAmount
      if (this.verifyNumber(maamolChargesTransporterAmount)) {
        this.setState({
          maamolChargesTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          maamolChargesTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' maamolChargesTransporterAmount validation fail';
      }

      // Verify  challanTruckerAmount
      if (this.verifyNumber(challanTruckerAmount)) {
        this.setState({
          challanTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          challanTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' challanTruckerAmount validation fail';
      }

      // Verify  penaltyTruckerAmount
      if (this.verifyNumber(penaltyTruckerAmount)) {
        this.setState({
          penaltyTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          penaltyTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' penaltyTruckerAmount validation fail';
      }

      // Verify  penaltyTruckerAmount
      if (this.verifyNumber(tdsTruckerAmount)) {
        this.setState({
          tdsTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          tdsTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' tdsTruckerAmount validation fail';
      }

      // Verify  fuelChargesTruckerAmount
      if (this.verifyNumber(fuelChargesTruckerAmount)) {
        this.setState({
          fuelChargesTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          fuelChargesTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' fuelChargesTruckerAmount validation fail';
      }

      // Verify  docChargesTruckerAmount
      if (this.verifyNumber(docChargesTruckerAmount)) {
        this.setState({
          docChargesTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          docChargesTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' docChargesTruckerAmount validation fail';
      }

      // Verify  maamolChargesTruckerAmount
      if (this.verifyNumber(maamolChargesTruckerAmount)) {
        this.setState({
          maamolChargesTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          maamolChargesTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' maamolChargesTruckerAmount validation fail';
      }

      // Verify  shortageChargesTruckerAmount
      if (this.verifyNumber(shortageChargesTruckerAmount)) {
        this.setState({
          shortageChargesTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          shortageChargesTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' shortageChargesTruckerAmount validation fail';
      }

      // Verify  lateDeliveryTruckerAmount
      if (this.verifyNumber(lateDeliveryTruckerAmount)) {
        this.setState({
          lateDeliveryTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          lateDeliveryTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' lateDeliveryTruckerAmount validation fail';
      }

      // Verify  latePodTruckerAmount
      if (this.verifyNumber(latePodTruckerAmount)) {
        this.setState({
          latePodTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          latePodTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' latePodTruckerAmount validation fail';
      }

      // Verify  earlySettlementTruckerAmount
      if (this.verifyNumber(earlySettlementTruckerAmount)) {
        this.setState({
          earlySettlementTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          earlySettlementTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' earlySettlementTruckerAmount validation fail';
      }

      if (!error) {
        if (this.checkValues()) {
          resolve(true);
        } else {
          this.setState({
            alert: (
              <SweetAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title="Warning"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={`${classes.button} ${classes.success}`}
              >
                Please verify your calculations.
              </SweetAlert>
            ),
          });
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Calculations do not match.');
        }
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors

        reject('validation Failed');
      }
    });
  }

  checkValues() {
    const {
      amount,
      commission,
      athPayable,
      bthPayable,
      athReceivable,
      bthReceivable,
    } = this.state;
    this.calculateFields();

    if (
      Number(amount) !== Number(athReceivable) + Number(bthReceivable) ||
      Number(amount) !==
        Number(athPayable) + Number(bthPayable) + Number(commission)
    )
      return false;
    return true;
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  submitTransaction() {
    const { classes, userDetails, systemDetails } = this.props;

    const {
      transporterName,
      truckerName,
      fromCity,
      toCity,
      amount,
      commission,

      athPayable,
      bthPayable,
      athReceivable,
      bthReceivable,
      hamaliChargesTrucker,
      haltingChargesTrucker,
      loadingChargesTrucker,
      unloadingChargesTrucker,
      hamaliChargesTransporter,
      haltingChargesTransporter,
      loadingChargesTransporter,
      unloadingChargesTransporter,
      hamaliChargesPaidByTrucker,
      haltingChargesPaidByTrucker,
      loadingChargesPaidByTrucker,
      unloadingChargesPaidByTrucker,
      hamaliChargesPaidByTransporter,
      haltingChargesPaidByTransporter,
      loadingChargesPaidByTransporter,
      unloadingChargesPaidByTransporter,
      challanTransporterAmount,
      penaltyTransporterAmount,
      tdsTransporterAmount,
      docChargesTransporterAmount,
      fuelChargesTransporterAmount,
      maamolChargesTransporterAmount,
      challanTruckerAmount,
      penaltyTruckerAmount,
      tdsTruckerAmount,
      fuelChargesTruckerAmount,
      docChargesTruckerAmount,
      maamolChargesTruckerAmount,
      shortageChargesTruckerAmount,
      lateDeliveryTruckerAmount,
      latePodTruckerAmount,
      earlySettlementTruckerAmount,
      tdsTruckerShowinloadingslip,
      fuelChargesTruckerShowinloadingslip,
      docChargesTruckerShowinloadingslip,
      maamolChargesTruckerShowinloadingslip,
      shortageChargesTruckerShowinloadingslip,
      lateDeliveryTruckerShowinloadingslip,
      latePodTruckerShowinloadingslip,
      earlySettlementTruckerShowinloadingslip,
      loadingDate,
      athReceivableDate,
      truckerId,
      transporterId,
      branch,
      truckNumber,
      truckType,
      loadingSubLocation,
      unloadingSubLocation,
      address,
      addressUnloading,
      ledgerId,
      truckerPhoneNumber,
      truckerPOC,
      transporterPOC,
      truckPlacedBy,
      orderRecievedBy,
      tonnage,
      expectedDate,
      driverNumber,
      transactionType,
      supplyID,
      demandID,
      showTds,
      hardCodeTransactionNumber,
      latestTransactionNumber,
      ewayBillNumber,
      isPodSubmittedAtUnloadingPoint,
    } = this.state;

    if (expectedDate.isBefore(loadingDate, 'day')) {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: 'block', marginTop: '-100px' }}
            title="Error"
            onConfirm={() => this.hideAlert()}
            confirmBtnCssClass={`${classes.button} ${classes.success}`}
          >
            Expected date cannot be before loading date.
          </SweetAlert>
        ),
        isLoading: false,
      });
      return;
    }

    this.setState({
      isLoading: true,
    });

    const sendValueTotransporterTdsDeducted = () =>
      latestTransactionNumber < hardCodeTransactionNumber || showTds;

    this.validateAllFields()
      .then(response => {
        let createdBy = '';
        let editedBy = '';
        let createdByName = '';
        let editedByName = '';
        createdBy = userDetails.uid;
        createdByName = userDetails.name;
        const user = firebase.auth().currentUser;
        const {
          netCommission,
          totalReceivableAmount,
          totalPayableAmount,
          athPayableAutoCalculated,
          bthPayableAutoCalculated,
        } = this.state;
        if (response) {
          const payload = {
            isEditedTransaction: false,
            agentId: user.uid,
            transporterName,
            truckerName,
            vehicleNumber: truckNumber,
            fromCity,
            toCity,
            amount,
            commission,
            netCommission,
            athPayable,
            bthPayable,
            athReceivable,
            bthReceivable,
            hamaliChargesTrucker,
            haltingChargesTrucker,
            loadingChargesTrucker,
            unloadingChargesTrucker,
            hamaliChargesTransporter,
            haltingChargesTransporter,
            loadingChargesTransporter,
            unloadingChargesTransporter,
            hamaliChargesPaidByTrucker,
            haltingChargesPaidByTrucker,
            loadingChargesPaidByTrucker,
            unloadingChargesPaidByTrucker,
            hamaliChargesPaidByTransporter,
            haltingChargesPaidByTransporter,
            loadingChargesPaidByTransporter,
            unloadingChargesPaidByTransporter,
            challanTransporterAmount,
            penaltyTransporterAmount,
            tdsTransporterAmount,
            docChargesTransporterAmount,
            fuelChargesTransporterAmount,
            maamolChargesTransporterAmount,
            challanTruckerAmount,
            penaltyTruckerAmount,
            tdsTruckerAmount,
            fuelChargesTruckerAmount,
            docChargesTruckerAmount,
            maamolChargesTruckerAmount,
            shortageChargesTruckerAmount,
            lateDeliveryTruckerAmount,
            latePodTruckerAmount,
            earlySettlementTruckerAmount,
            tdsTruckerShowinloadingslip,
            fuelChargesTruckerShowinloadingslip,
            docChargesTruckerShowinloadingslip,
            maamolChargesTruckerShowinloadingslip,
            shortageChargesTruckerShowinloadingslip,
            lateDeliveryTruckerShowinloadingslip,
            latePodTruckerShowinloadingslip,
            earlySettlementTruckerShowinloadingslip,
            totalPayableAmount,
            totalReceivableAmount,
            loadingDate: loadingDate.format('MMM Do YY'),
            athReceivableDate: athReceivableDate.format('MMM Do YY'),
            truckerId,
            transporterId,
            branch,
            athPayableAutoCalculated,
            bthPayableAutoCalculated,
            truckNumber,
            truckType,
            loadingSubLocation,
            unloadingSubLocation,
            address,
            addressUnloading,
            ledgerId,
            limitPayable: true,
            loadingAddress: address,
            unloadingAddress: addressUnloading,
            truckerPhoneNumber,
            riskStatus: 'green',
            currentVehicleStatus: 'Transaction Created',
            currentVehicleStatusUpdatedById: createdBy,
            currentVehicleStatusUpdatedByName: createdByName,
            currentVehicleLocation: '',
            currentVehicleLocationLattitude: '',
            currentVehicleLocationLongitude: '',
            truckerPocName: truckerPOC.label,
            truckerPocId: truckerPOC.value,
            transporterPocId: transporterPOC.value,
            transporterPocName: transporterPOC.label,
            truckPlacedByName: truckPlacedBy.label ? truckPlacedBy.label : '',
            truckPlacedById: truckPlacedBy.value ? truckPlacedBy.value : '',
            orderRecievedByName: orderRecievedBy.label
              ? orderRecievedBy.label
              : '',
            orderRecievedById: orderRecievedBy.value
              ? orderRecievedBy.value
              : '',
            tonnage,
            expectedDate: expectedDate ? expectedDate.format('MMM Do YY') : '',
            driversPhoneNumber: driverNumber,
            createdBy,
            createdByName,
            editedBy,
            editedByName,
            expressTrip: transactionType === 'Express',
            tatTrip: transactionType === 'TAT',
            supplyId: supplyID,
            demandId: demandID,
            transporterTdsDeducted: sendValueTotransporterTdsDeducted(),
            ewayBillNumber,
            isPodSubmittedAtUnloadingPoint,
          };
          addTransaction(payload)
            .then(docRef => {
              const auditBody = {
                data: {
                  ...payload,
                },
                collection: 'Transactions',
                updatedBy: userDetails.uid,
                systemDetails,
                type: 'Create Transaction',
                message: `${userDetails.email} created a new Transaction with GFV ${amount}, Commission ${commission}, From ${fromCity} To ${toCity}`,
              };
              auditLog(auditBody);
              this.clearForm();
              this.setState({ isLoading: false });
            })
            .catch(err => {
              const errorBody = {
                message: `${userDetails.email} tried to create a new Transaction but FAILED`,
                status: 'fail',
                error: err,
                updatedBy: userDetails.uid,
                errorMessage: err.message,
                collection: 'Transactions',
                systemDetails,
              };
              auditLog(errorBody);
              if (err.message === 'Parameter Missing') {
                this.setState({
                  alert: (
                    <SweetAlert
                      error
                      style={{ display: 'block', marginTop: '-100px' }}
                      title="Error"
                      onConfirm={() => this.hideAlert()}
                      confirmBtnCssClass={`${classes.button} ${classes.success}`}
                    >
                      Please verify fields/calculations.
                    </SweetAlert>
                  ),
                });
                this.setState({ isLoading: false });
              } else {
                this.setState({
                  alert: (
                    <SweetAlert
                      error
                      style={{ display: 'block', marginTop: '-100px' }}
                      title="Error"
                      onConfirm={() => this.hideAlert()}
                      confirmBtnCssClass={`${classes.button} ${classes.success}`}
                    >
                      Oops! Something went wrong.
                    </SweetAlert>
                  ),
                });
                this.setState({ isLoading: false });
              }
              this.setState({ isLoading: false });
            });
        }
      })
      .catch(e => {
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    const { classes } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.confirmAlert()}
          onCancel={() => this.confirmAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        >
          Transaction Added Succeessfully
        </SweetAlert>
      ),
    });
    this.setState({
      transporterName: '',
      transporterId: '',
      truckerName: '',
      truckerId: '',
      fromCity: '',
      fromCityState: '',
      toCity: '',
      toCityState: '',
      vehicleNumber: '',
      vehicleNumberState: '',
      amount: '0',
      amountState: '',
      commission: '0',
      commissionState: '',
      truckType: '',
      truckTypeState: '',
      truckNumber: '',
      truckNumberState: '',
      isLoading: false,
      loadingCharges: '0',
      loadingChargesState: '',
      bthReceivable: '0',
      bthReceivableState: '',
      bthPayable: '0',
      bthPayableState: '',
      athReceivable: '0',
      athReceivableState: '',
      athPayable: '0',
      athPayableState: '',
      haultingCharges: '0',
      haultingChargesState: '',
      hamaliCharges: '0',
      hamaliChargesState: '0',
      loadingChargesPaidTransporter: false,
      unloadingChargesPaidTransporter: false,
      hamaliChargesPaidTransporter: false,
      haultingChargesPaidTransporter: false,
      munshiana: '0',
      munshianaState: '',
      penalty: '0',
      penaltyState: '',
      munshianaTrucker: false,
      munshianaTransporter: false,
      penaltyTrucker: false,
      penaltyTransporter: false,
      tds: '0',
      tdsTransporter: false,
      tdsTrucker: false,
      docCharges: '0',
      docChargesTrucker: false,
      docChargesTransporter: false,
      fuelCharges: '0',
      fuelChargesTrucker: false,
      fuelChargesTransporter: false,
      athPayableAutoCalculated: '0',
      bthPayableAutoCalculated: '0',
      ledgerId: '',
      truckerPhoneNumber: '',
    });
  }

  handleSimple = event => {
    this.setState({ branch: event.value, selectedBranch: event });
  };

  getTruckerSecondaryContacts = async ID => {
    // setFetching(true);
    this.setState({
      fetching: true,
    });
    let CustomersData = [];
    db.collection('Customers')
      .where('orgId', '==', ID)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const { name, customerId } = doc.data();
          // doc.data() is never undefined for query doc snapshots
          CustomersData.push({ value: customerId, label: name });
        });

        // setCustomers(CustomersData);
        // setFetching(false);
        this.setState({
          truckerCustomers: CustomersData,
          fetching: false,
        });
      })
      .catch(error => {
        // setFetching(false);
        this.setState({
          fetching: false,
        });
      });
  };

  handleTruckerPOC = customer => {
    this.setState({
      truckerPOC: customer,
    });
  };

  handleOrderRecievedBy = traffic => {
    this.setState({ orderRecievedBy: traffic });
  };

  handleTruckPlacedBy = traffic => {
    this.setState({ truckPlacedBy: traffic });
  };

  handleTrucker = selectedTrucker => {
    // TODO:Limit client side data access
    this.setState({
      truckerPOC: '',
      selectedTruckHasRCUploadedBool: '',
      showRCUploder: false,
    });
    if (!selectedTrucker?.blacklisted) {
      this.setState({
        selectedTruckerId: selectedTrucker.value.truckerId,
      });
      this.getTruckerSecondaryContacts(selectedTrucker.value.truckerId);
      if (selectedTrucker.value.trucks) {
        let newTruckTypes = [];
        let truckOptions = [];
        selectedTrucker.value.trucks.forEach(truck => {
          // Only include trucks where void is not explicitly true
          if (truck.void !== true) {
            newTruckTypes.push({
              label: truck.truckNumber,
              value: truck.truckNumber,
              ...truck,
            });
            truckOptions.push({
              label: truck.truckNumber,
              value: truck.truckNumber,
            });
          }
        });
        this.setState({
          trucks: newTruckTypes,
          selectedTruckerTrucksOptions: truckOptions,
          truckType: '',
          truckNumber: '',
        });
      }
      this.setState({
        selectedTrucker: selectedTrucker.value,
        selectedTruck: null,
        truckerName: selectedTrucker.value.name,
        truckerId: selectedTrucker.value.truckerId,
        ledgerId: selectedTrucker.value.ledgerId,
        truckerPhoneNumber: selectedTrucker.value.phoneNumber,
        showRCUploder: false,
      });
    }

    this.getTdsDeduction(selectedTrucker.value.truckerId);
  };

  async getTdsDeduction(truckerId, retries = 2) {
    const { classes } = this.props;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const endPoint = `pipe/trucker-tds/status?trucker_id=${truckerId}`;

    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${Aa}`,
        'nh-base',
        'get'
      );
      const tdsDeclaration = response?.data?.trucker_tds_declaration_submitted;
      const validPanCheck = response?.data?.valid_pan;

      if (tdsDeclaration === false) {
        this.setState({
          tdsTruckerSubmitted: false,
        });
      }
      if (validPanCheck === false) {
        this.setState({
          validPan: false,
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              Please update a Valid PAN to continue using this Trucker.
            </SweetAlert>
          ),
        });
      }
    } catch (error) {
      console.error(error);

      this.setState({
        validPan: false,
      });

      if (retries > 0) {
        this.getTdsDeduction(truckerId, retries - 1);
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              Something Went Wrong, Please Try Again, Page Will Refresh in 2
              Seconds
            </SweetAlert>
          ),
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  }

  async checkIfRcISUploaded(truckNumber, truckerId) {
    const { featureFlagFiles } = this.state;
    // NOTE Check if selecetd truck has RC uploaded or not
    if (featureFlagFiles === 2) {
      const viewURLBody = {
        scope: 'truck',
        type: 'view',
        file_type: 'truck-rc',
        trucker_id: truckerId,
        truck_number: truckNumber,
      };
      getViewURL(viewURLBody)
        .then(result => {
          if (result?.data?.url) {
            this.setState({
              showRCUploder: false,
              selectedTruckHasRCUploadedBool: true,
            });
          }
        })
        .catch(e => {
          this.setState({
            showRCUploder: true,
            selectedTruckHasRCUploadedBool: false,
          });
        });
    } else if (featureFlagFiles === 0) {
      const docRef = await db
        .collection('FileUploads')
        .doc('Truck_Files')
        .collection('RC')
        .doc(this.state.selectedTruckerId);

      docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();

            const { rcFileUploadURL } = data[truckNumber];
            if (rcFileUploadURL) {
              this.setState({
                showRCUploder: false,
                selectedTruckHasRCUploadedBool: true,
              });
            }
          } else {
            this.setState({
              showRCUploder: true,
              selectedTruckHasRCUploadedBool: false,
            });
          }
        })
        .catch(error => {
          this.setState({
            showRCUploder: true,
            selectedTruckHasRCUploadedBool: false,
          });
        });
    }
  }

  handleTrucks = event => {
    this.setState({
      showRCUploder: false,
      // selectedTruckHasRCUploadedBool: false,
    });
    const { trucks } = this.state;
    for (const i in trucks) {
      if (trucks[i].label === event.label) {
        this.setState({ truckType: trucks[i].value });
      }
    }
    this.setState({ truckNumber: event.label, selectedTruck: event });
    this.checkIfRcISUploaded(event.label, this.state.selectedTruckerId);
  };

  handleFromCity = selectedCity => {
    this.setState({
      selectedFromCity: selectedCity,
      fromCity: selectedCity.value,
    });
  };

  handleToCity = selectedCity => {
    this.setState({
      selectedToCity: selectedCity,
      toCity: selectedCity.value,
    });
  };

  getSecondaryContacts = async ID => {
    // setFetching(true);
    this.setState({
      fetching: true,
    });
    let CustomersData = [];
    db.collection('Customers')
      .where('orgId', '==', ID)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const { name, customerId } = doc.data();
          // doc.data() is never undefined for query doc snapshots
          CustomersData.push({ value: customerId, label: name });
        });
        this.setState({
          transporterCustomers: CustomersData,
          fetching: false,
        });
      })
      .catch(error => {
        // setFetching(false);
        this.setState({
          fetching: false,
        });
      });
  };

  handleTransporterPOC = customer => {
    this.setState({
      transporterPOC: customer,
    });
  };

  handleTransporter = selectedTransporter => {
    this.setState({ transporterPOC: '' });
    this.tdsDeductedFlagHandler(selectedTransporter);
    this.latestTransactionNumberFromApi();

    if (!selectedTransporter?.blacklisted) {
      this.setState({
        selectedTransporter,
        transporterName: selectedTransporter.name,
        transporterId: selectedTransporter.transporterId,
      });
      this.getSecondaryContacts(selectedTransporter.transporterId);
    }
  };

  handlePaidByTruckerSwitch = name => event => {
    this.setState({ [name]: event.target.checked }, this.calculateFields);
  };

  // Will Return select From cities component
  selectFromCities() {
    const { classes } = this.props;
    const { fromCitiesObject, selectedFromCity } = this.state;
    return (
      <div>
        <InputLabel className={classes.labelText}>Select From City</InputLabel>
        <ReactSelect
          value={selectedFromCity}
          onChange={this.handleFromCity}
          options={fromCitiesObject}
          placeholder="Select City"
        />
      </div>
    );
  }

  async tdsDeductedFlagHandler(selectedTransporter, retries = 2) {
    let { activeRole, classes } = this?.props;
    const role = activeRole === 'branch-ops' ? 'BranchOps' : activeRole;
    const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(
      1
    )}`;
    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;

    const endPoint = `pipe/check-tds-deduction?role=${currentActiveRole}&transporter_id=${selectedTransporter?.transporterId}`;

    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const value = response?.data?.is_tds_deduction;
      if (value === false) {
        this.setState({
          showTds: value,
        });
      }
    } catch (e) {
      console.error(e);
      if (retries > 0) {
        this.tdsDeductedFlagHandler(selectedTransporter, retries - 1);
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              Something Went Wrong, Please Try Again, Page Will Refresh in 2
              Seconds
            </SweetAlert>
          ),
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  }

  async latestTransactionNumberFromApi(selectedTransporter, retries = 2) {
    const { classes } = this.props;
    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;

    const endPoint = `pipe/latest-transaction-number`;

    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const value = response?.data?.latest_transaction;

      this.setState({
        latestTransactionNumber: value,
      });
    } catch (e) {
      console.error(e);
      if (retries > 0) {
        this.latestTransactionNumberFromApi(selectedTransporter, retries - 1);
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              Something Went Wrong, Please Try Again, Page Will Refresh in 2
              Seconds
            </SweetAlert>
          ),
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  }

  // Will Return select From cities component
  selectToCities() {
    const { classes } = this.props;
    const { fromCitiesObject, selectedToCity } = this.state;
    return (
      <div>
        <InputLabel className={classes.labelText}>Select To City</InputLabel>
        <ReactSelect
          value={selectedToCity}
          onChange={this.handleToCity}
          options={fromCitiesObject}
          placeholder="Select City"
        />
      </div>
    );
  }

  selectManufacturer() {
    const { classes } = this.props;
    const { manufacturerList, selectedManufacturer } = this.state;

    return (
      <div
        style={{ marginTop: '5px', marginLeft: '12px', marginRight: '15px' }}
      >
        <InputLabel className={classes.labelText}>
          Select Manufacturer/Warehouse
        </InputLabel>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            <ReactSelect
              value={selectedManufacturer}
              options={manufacturerList}
              placeholder="Select Manufacturer/Warehouse"
              onChange={val => this.setState({ selectedManufacturer: val })}
            />
          </div>

          <AddManufacturer
            refetchList={() =>
              getManufacturerList().then(listData =>
                this.setState({ manufacturerList: listData })
              )
            }
          />
        </div>
      </div>
    );
  }

  handleCheckbox(stateName) {
    let current = this.state[stateName];
    current = !current;
    this.setState({ [stateName]: current }, this.calculateFields);
  }

  autoCalculated() {
    const { classes } = this.props;
    const { athPayableAutoCalculated, bthPayableAutoCalculated } = this.state;
    return (
      <Card>
        <CardHeader color="primary" icon>
          <h3 className={classes.cardIconTitle}>Auto Calculated</h3>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="ATH Payable"
                id="athPayable"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'athPayable', 'number'),
                  disabled: true,
                  type: 'number',
                  value: athPayableAutoCalculated,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="BTH Payable"
                id="bthPayable"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'bthPayable', 'number'),
                  type: 'number',
                  disabled: true,
                  value: bthPayableAutoCalculated,
                }}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }

  deductions() {
    const { classes } = this.props;

    const handleDeductionsTransporter = (state, val) => {
      if (val) {
        if (this.verifyNumber(val)) {
          this.setState({ [state]: val, [`${state}Error`]: 'success' });
        } else {
          this.setState({ [state]: val, [`${state}Error`]: 'error' });
        }
      } else {
        this.setState({ [state]: val, [`${state}Error`]: '' });
      }
    };

    const handleDeductionsTrucker = (state, val) => {
      if (val) {
        if (this.verifyNumber(val)) {
          this.setState({ [state]: val, [`${state}Error`]: 'success' });
          setTimeout(() => {
            this.calculateFields();
          }, 1000);
        } else {
          this.setState({ [state]: val, [`${state}Error`]: 'error' });
        }
      } else {
        this.setState({ [state]: val, [`${state}Error`]: '' });
      }
    };
    const handleMoreDeductionsTrucker = (state, val) => {
      if (val) {
        if (this.verifyNumber(val)) {
          this.setState({ [state]: val, [`${state}Error`]: 'success' });
          setTimeout(() => {
            this.calculateFields();
          }, 1000);
        } else {
          this.setState({ [state]: val, [`${state}Error`]: 'error' });
        }
      } else {
        this.setState({ [state]: val, [`${state}Error`]: '' });
      }
    };
    return (
      <TripDeductionFields
        classes={classes}
        state={this.state}
        handleDeductionsTransporter={(state, val) =>
          handleDeductionsTransporter(state, val)
        }
        handleDeductionsTrucker={(state, val) =>
          handleDeductionsTrucker(state, val)
        }
        handleMoreDeductionsTrucker={(state, val) =>
          handleMoreDeductionsTrucker(state, val)
        }
      />
    );
  }

  otherExpenses() {
    const { classes } = this.props;

    const handleOtherExpensesByTrucker = (state, val) => {
      if (this.verifyNumber(val)) {
        this.setState({ [state]: val, [`${state}Error`]: 'success' });
        setTimeout(() => {
          this.calculateFields();
        }, 1000);
      } else {
        this.setState({ [state]: val, [`${state}Error`]: 'error' });
      }
    };
    const handleOtherExpensesByTransporter = (state, val) => {
      if (this.verifyNumber(val)) {
        this.setState({ [state]: val, [`${state}Error`]: 'success' });
      } else {
        this.setState({ [state]: val, [`${state}Error`]: 'error' });
      }
    };

    return (
      <OtherExpenses
        classes={classes}
        handleOtherExpensesByTrucker={(state, val) =>
          handleOtherExpensesByTrucker(state, val)
        }
        handleOtherExpensesByTransporter={(state, val) =>
          handleOtherExpensesByTransporter(state, val)
        }
        states={this.state}
      />
    );
  }

  ewayBillNumber() {
    const handleEwayBillNumberChange = ewayBillNumber => {
      this.setState({ ewayBillNumber });
    };

    return (
      <EwayBillNumber onEwayBillNumberChange={handleEwayBillNumberChange} />
    );
  }

  handleChangeTransactionType(event) {
    this.setState({ transactionType: event.target.value });
  }

  loadingDateChange(loadingDate) {
    this.setState({ loadingDate });
  }

  athReceivableDateChange(athReceivableDate) {
    this.setState({ athReceivableDate });
  }

  expectedDateChange(expectedDate) {
    this.setState({ expectedDate });
  }

  handleChangeUnloadingPoint(event) {
    this.setState({
      isPodSubmittedAtUnloadingPoint: event.target.value === 'true',
    });
  }

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      textDecoration: state.data.blacklisted ? 'line-through' : 'none',
      textDecorationThickness: state.data.blacklisted ? '2px' : 'none',
      color: state.data.blacklisted ? 'red' : provided.color,
    }),
  };

  render() {
    const { classes, userDetails } = this.props;
    const {
      selectedTransporter,
      transporters,
      // truckers,
      selectedTrucker,
      alert,
      transporterNameState,
      truckerNameState,
      branchState,
      fromCityState,
      toCityState,
      truckNumberState,
      truckType,
      truckTypeState,
      amount,
      amountState,
      commission,
      commissionState,
      athReceivable,
      athReceivableState,
      athPayable,
      athPayableState,
      bthReceivable,
      bthPayableState,
      loadingDate,
      bthPayable,
      athReceivableDate,
      bthReceivableState,
      isLoading,
      branches,
      selectedTruck,
      selectedBranch,
      truckersOptions,
      tonnageState,
      tonnage,
      expectedDate,
      driverNumberState,
      driverNumber,
      expectedDateState,
      selectedTruckHasRCUploadedBool,
      featureFlagFiles,
      transactionType,
      supplyID,
      supplyIDState,
      demandID,
      demandIDState,
      isValidatingID,
      isDemandValid,
      isSupplyValid,
      validPan,
      isPodSubmittedAtUnloadingPoint,
    } = this.state;
    const searchOptions = {
      componentRestrictions: { country: 'in' },
    };

    const now = moment();
    const startYear = now.month() < 3 ? now.year() - 1 : now.year();
    const endYear = startYear + 1;
    const financialYear = `FY ${startYear
      .toString()
      .slice(-2)}-${endYear.toString().slice(-2)}`;

    return (
      <div>
        {featureFlagFiles === 1 && <FeatureFlagToastAlert />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="info">
                <CardIcon color="primary" className={`${classes.cardHeader}`}>
                  <SwapHoriz />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Add transaction <small />
                </h4>
              </CardHeader>
              <CardBody mt={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="transactionType"
                        name="transactionType"
                        value={transactionType}
                        onChange={this.handleChangeTransactionType}
                        row
                      >
                        <FormControlLabel
                          value="TAT"
                          control={<Radio color="primary" />}
                          label="Normal"
                        />
                        <FormControlLabel
                          value="Express"
                          control={<Radio color="primary" />}
                          label="TAT & Express"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{ marginBottom: '10px' }}>
                      <InputLabel className={classes.labelText}>
                        Select Transporter
                      </InputLabel>

                      <ReactSelect
                        value={selectedTransporter}
                        styles={this.customStyles}
                        onChange={this.handleTransporter}
                        options={transporters}
                        placeholder="Select Transporter"
                      />

                      {transporterNameState === 'This field is required' && (
                        <Danger>{transporterNameState}</Danger>
                      )}
                    </div>
                    {this.state.transporterCustomers.length !== 0 ? (
                      <div>
                        <InputLabel className={classes.labelText}>
                          Select Transporter POC
                        </InputLabel>
                        <ReactSelect
                          value={this.state.transporterPOC}
                          onChange={this.handleTransporterPOC}
                          options={this.state.transporterCustomers}
                          placeholder="Select Transporter POC"
                        />
                        {transporterNameState === 'This field is required' && (
                          <Danger>{transporterNameState}</Danger>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{ marginBottom: '10px' }}>
                      <InputLabel className={classes.labelText}>
                        Select Trucker
                      </InputLabel>

                      <ReactSelect
                        value={selectedTrucker}
                        onChange={this.handleTrucker}
                        options={truckersOptions}
                        placeholder="Select Trucker"
                        styles={this.customStyles}
                      />
                      {truckerNameState === 'This field is required' && (
                        <Danger>{truckerNameState}</Danger>
                      )}
                      {this.state.tdsTruckerSubmitted === false && (
                        <Typography
                          style={{ fontSize: '12px', color: '#D14343' }}
                        >
                          TDS Declaration for {financialYear} not submitted
                        </Typography>
                      )}
                    </div>
                    {this.state.truckerCustomers.length !== 0 ? (
                      <div>
                        <InputLabel className={classes.labelText}>
                          Select Trucker POC
                        </InputLabel>
                        <ReactSelect
                          value={this.state.truckerPOC}
                          onChange={this.handleTruckerPOC}
                          options={this.state.truckerCustomers}
                          placeholder="Select Trucker POC"
                        />
                        {truckerNameState === 'This field is required' && (
                          <Danger>{truckerNameState}</Danger>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'baseline',
                      }}
                    >
                      <CustomInput
                        labelText="Demand ID"
                        id="demandId"
                        formControlProps={{ fullWidth: true }}
                        inputProps={
                          {
                            onChange: event =>
                              this.change(event, 'demandID', 'isAlphaNumeric'),

                            value: demandID,
                          } //  disabled: true,
                        }
                        success={
                          demandIDState === 'success' &&
                          isDemandValid === 'isValid'
                        }
                        error={
                          demandIDState === 'This field is required' ||
                          isDemandValid === 'isNotValid'
                        }
                      />
                      {isValidatingID === 'demandID' && (
                        <div>
                          <CircularProgress size={20} />
                        </div>
                      )}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'baseline',
                      }}
                    >
                      <CustomInput
                        labelText="Supply ID"
                        id="supplyID"
                        formControlProps={{ fullWidth: true }}
                        inputProps={
                          {
                            onChange: event =>
                              this.change(event, 'supplyID', 'isAlphaNumeric'),

                            value: supplyID,
                          } //  disabled: true,
                        }
                        success={
                          supplyIDState === 'success' &&
                          isSupplyValid === 'isValid'
                        }
                        error={
                          supplyIDState === 'This field is required' ||
                          isSupplyValid === 'isNotValid'
                        }
                      />
                      {isValidatingID === 'supplyID' && (
                        <div>
                          <CircularProgress size={20} />
                        </div>
                      )}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginTop: '10px' }}>
                  {userDetails.branches.length > 0 && (
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel className={classes.labelText}>
                        Select Branch
                      </InputLabel>
                      <ReactSelect
                        value={selectedBranch}
                        onChange={this.handleSimple}
                        options={branches}
                        placeholder="Select Branch"
                      />
                      {branchState === 'This field is required' && (
                        <Danger>{branchState}</Danger>
                      )}
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <InputLabel className={classes.labelText}>
                        Select Trucks
                      </InputLabel>
                      {selectedTruckHasRCUploadedBool === false && (
                        <Tooltip title="Selected Truck does not have RC uploaded">
                          <IconButton
                            style={{ padding: 0, paddingLeft: 5 }}
                            aria-label="delete"
                          >
                            <InfoIcon
                              style={{ fontSize: 20, color: 'red', padding: 0 }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '100%' }}>
                        <ReactSelect
                          value={selectedTruck}
                          onChange={this.handleTrucks}
                          options={this.state.selectedTruckerTrucksOptions}
                          placeholder="Select Trucks"
                        />
                      </div>
                      {featureFlagFiles !== 1 && (
                        <>
                          {this.state.showRCUploder && (
                            <FileUploader
                              truckNumber={this.state.truckNumber}
                              truckerId={selectedTrucker?.truckerId}
                              rcFileForExisting
                              rcUploaded={val => {
                                this.setState({
                                  setRCUploaded: val,
                                  selectedTruckHasRCUploadedBool: val,
                                });
                              }}
                              featureFlagFiles={featureFlagFiles}
                            />
                          )}
                        </>
                      )}

                      {featureFlagFiles !== 1 && (
                        <AddTruck
                          truckerId={this.state.selectedTruckerId}
                          trucks={this.state.trucks}
                          updateTrucks={(value, options) => {
                            this.setState({
                              trucks: value,
                              truckOptions: value,
                              selectedTruckerTrucksOptions: options,
                            });
                          }}
                          activeRole={this.props.activeRole}
                          user={this.props.userDetails}
                          selectedTruckHasRCUploadedBool={val => {
                            this.setState({
                              selectedTruckHasRCUploadedBool: val,
                            });
                          }}
                          featureFlagFiles={featureFlagFiles}
                        />
                      )}
                    </div>

                    {truckNumberState === 'This field is required' && (
                      <Danger>{truckNumberState}</Danger>
                    )}
                  </GridItem>
                </GridContainer>
                <Grid container style={{ marginTop: '10px' }} spacing={4}>
                  <Grid item xs={12} sm={12} md={6}>
                    {this.selectFromCities()}
                    {fromCityState === 'This field is required' && (
                      <Danger>{fromCityState}</Danger>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    {this.selectToCities()}
                    {toCityState === 'This field is required' && (
                      <Danger>{toCityState}</Danger>
                    )}
                  </Grid>{' '}
                </Grid>
                <GridContainer
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabelSearch}
                    >
                      Select Loading Point
                    </InputLabel>
                    <PlacesAutocomplete
                      value={this.state.address}
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'Enter loading point',
                              className: 'location-search-input',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer',
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer',
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </GridItem>
                  {/* <Grid item xs={12} sm={12} md={6}>
                    {this.selectManufacturer()}
                  </Grid> */}
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabelSearch}
                    >
                      Select Unloading Point
                    </InputLabel>
                    <PlacesAutocomplete
                      value={this.state.addressUnloading}
                      onChange={this.handleChangeUnloading}
                      onSelect={this.handleSelectUnloading}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'Enter unloading point',
                              className: 'location-search-input',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer',
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer',
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginTop: '30px' }}>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Truck Type"
                      id="credit-limit"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{ disabled: true, value: truckType }}
                      success={truckTypeState === 'success'}
                      error={truckTypeState === 'This field is required'}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Freight Value"
                      id="amountr"
                      // fontBig
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'amount', 'greater', 1),
                        type: 'greater',
                        value: amount,
                      }}
                      success={amountState === 'success'}
                      error={amountState === 'This field is required'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Commission"
                      id="commission"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'commission', 'number'),
                        type: 'number',
                        value: commission,
                      }}
                      success={commissionState === 'success'}
                      error={commissionState === 'This field is required'}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="ATH Receivable"
                      id="athReceivable"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'athReceivable', 'number'),
                        type: 'number',
                        value: athReceivable,
                      }}
                      success={athReceivableState === 'success'}
                      error={athReceivableState === 'This field is required'}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="BTH Receivable"
                      id="bthReceivable"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        disabled: true,
                        type: 'number',
                        value: bthReceivable,
                      }}
                      success={bthReceivableState === 'success'}
                      error={bthReceivableState === 'This field is required'}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <CustomInput
                        labelText="Trucker ATH"
                        id="athPayable"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            this.change(
                              event,
                              'athPayable',
                              'maxNumber',
                              199999
                            ),
                          type: 'number',
                          value: athPayable,
                          disabled: true,
                        }}
                        success={athPayableState === 'success'}
                        error={athPayableState === 'Invalid'}
                      />
                      {this.state.truckerATHPercentage > 0 && (
                        <div
                          style={{
                            height: '40px',
                            border:
                              this.state.truckerATHPercentage <= 100
                                ? '4px solid #3DAC16'
                                : '4px solid #FF0000',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '10px',
                            padding: '10px',
                          }}
                        >
                          <Typography variant="h6">
                            {this.state.truckerATHPercentage}%
                          </Typography>
                        </div>
                      )}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <CustomInput
                        labelText="Trucker BTH"
                        id="bthPayable"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'bthPayable', 'number'),
                          type: 'number',
                          value: bthPayable,
                          disabled: true,
                        }}
                        success={bthPayableState === 'success'}
                        error={bthPayableState === 'Invalid'}
                      />
                      {this.state.truckerBTHPercentage > 0 && (
                        <div
                          style={{
                            height: '40px',
                            border:
                              this.state.truckerBTHPercentage <= 100
                                ? '4px solid #3DAC16'
                                : '4px solid #FF0000',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '10px',
                            padding: '10px',
                          }}
                        >
                          <Typography variant="h6">
                            {this.state.truckerBTHPercentage}%
                          </Typography>
                        </div>
                      )}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel className={classes.label}>
                      Loading Date
                    </InputLabel>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        onChange={this.loadingDateChange}
                        dateFormat="DD/MM/YYYY"
                        value={loadingDate}
                        timeFormat={false}
                        inputProps={{ placeholder: 'Select Date' }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel className={classes.label}>
                      Expected ATH Receivable Date
                    </InputLabel>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        onChange={this.athReceivableDateChange}
                        value={athReceivableDate}
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{ placeholder: 'Select Date' }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel className={classes.label}>
                      Expected Delivery Date
                    </InputLabel>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        onChange={this.expectedDateChange}
                        value={expectedDate}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        inputProps={{ placeholder: 'Select Date' }}
                      />
                      {expectedDateState === 'This field is required' && (
                        <Danger>{expectedDateState}</Danger>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Tonnage"
                      id="tonnage"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => {
                          const inputValue = event.target.value;
                          if (/^\d{0,2}(\.\d{0,2})?$/.test(inputValue)) {
                            this.change(event, 'tonnage', 'tonnage');
                          }
                        },
                        type: 'text',
                        value: tonnage,
                      }}
                      success={tonnageState === 'success'}
                      error={tonnageState === 'This field is required'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Driver Phone Number"
                      id="driverNumber"
                      formControlProps={{ fullWidth: true }}
                      inputProps={
                        {
                          onChange: event =>
                            this.change(event, 'driverNumber', 'driverNumber'),

                          value: driverNumber,
                        } //  disabled: true,
                      }
                      success={driverNumberState === 'success'}
                      error={driverNumberState === 'This field is required'}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <text
                      style={{
                        fontSize: '14px',
                        color: '#858585',
                        fontWeight: '500',
                      }}
                    >
                      POD Submitted at Unloading Point?
                    </text>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="POD Unloading Point"
                        name="POD Unloading Point"
                        value={isPodSubmittedAtUnloadingPoint}
                        onChange={this.handleChangeUnloadingPoint}
                        row
                      >
                        <FormControlLabel
                          value
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </GridContainer>

                {userDetails.branches.length > 0 && (
                  <GridItem xs={12} sm={12} md={6} />
                )}
                <GridContainer style={{ marginTop: '20px' }}>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.otherExpenses()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.deductions()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.autoCalculated()}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.ewayBillNumber()}
                  </GridItem>
                </GridContainer>
                {isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    color="info"
                    onClick={this.submitTransaction}
                    className={classes.updateProfileButton}
                    disabled={
                      !selectedTruckHasRCUploadedBool ||
                      featureFlagFiles === 1 ||
                      validPan === false
                    }
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AddTransactions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  isDetailedPage: state.transactions.isDetailedPage,
  transactions: state.transactions.transactions,
  systemDetails: state.main.systemDetails,
  activeRole: state.main.activeRole,
});

export default connect(mapStateToProps)(
  withStyles(AddTruckersStyle)(AddTransactions)
);
