/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  Checkbox,
  CircularProgress,
  Paper,
  Snackbar,
  makeStyles,
} from '@material-ui/core';
import firebase from 'firebase/app';
import { Alert } from '@material-ui/lab';
import apiCaller from '../../../utils/apiCallerOutstanding';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const paperStyles = { padding: 10, marginTop: 20 };

function IsMsmeRegistered({
  truckerId,
  activeRole,
  isMsme,
  fetchIsMsme,
  blacklist,
}) {
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isChecboxUpdating, setIsChecboxUpdating] = useState(false);

  async function updateMsme(isMsmeApproved) {
    setIsChecboxUpdating(true);
    const currentUserDetails = firebase.auth().currentUser;
    const { Aa } = currentUserDetails;
    const userToken = Aa;
    const body = {
      trucker_id: truckerId,
      type: 'msme',
      msme_enabled: isMsmeApproved,
    };
    const endPoint = `pipe/truckers/tds-percentage`;
    try {
      await apiCaller(endPoint, body, `Bearer ${userToken}`, 'nh-base', 'post');
      setSnackbarMessage('Successfully Updated the MSME');
      await fetchIsMsme();
      setIsChecboxUpdating(false);
    } catch (error) {
      setSnackbarMessage('Failed to Updated the MSME');
      setIsChecboxUpdating(false);
      console.error('Error in Msme Data:', error);
    }
    setSnackbarOpen(true);
  }

  return (
    <Paper elevation={1} style={paperStyles}>
      <div className={classes.headerWrapper}>
        <p
          style={{
            margin: '14px 20px',
            textAlign: 'left',
            fontSize: '26px',
            fontWeight: 400,
          }}
        >
          Is MSME Registered
        </p>
        {isChecboxUpdating ? (
          <CircularProgress size={30} />
        ) : (
          <Checkbox
            size="medium"
            onChange={async e => {
              await updateMsme(e.target.checked);
            }}
            checked={isMsme}
            disabled={
              !(activeRole === 'ops' && activeRole === 'admin') && blacklist
            }
          />
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={
            snackbarMessage === 'Successfully Updated the MSME'
              ? 'success'
              : 'error'
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default IsMsmeRegistered;
